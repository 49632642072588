import React from "react";
import { Helmet } from "react-helmet";
import CustomFields from "../../components/CustomFields";
import Blueberry from "../../../blueberry/index";
import Icons from "./components/Icons";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import withTracker from "../../../blueberry/connectPromise";
import { getYearsSinceToday } from "../../utils";
import {
    Wrapper,
    TopImage,
    SellerCard,
    ProfileExtended,
    SellerInfo,
    Footer,
    BrandBtn,
    TextContainer,
    Gallery,
    ContainerExtended,
    Banner,
    InfoIcon,
    Mosaic,
    PostList,
    Post,
} from "./style";
import { Icon } from "../../sdk/Button";
import { Columns, Column, Title, Heading, Box } from "../../sdk/Layout";
import { Container, Section, PageContent } from "../../sdk/Layout";
import InternalLink from "../../components/InternalLink";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import Listener from "../../components/Listener";
import FullScreen from "../../components/Gallery2/FullScreen";
import renderIf from "../../utils/renderIf";
import { NotFound } from "../../NoMatch";
import CustomField from "../../components/CustomFields";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

class Page extends ProductsSectionTemplate {
    state = {
        isModalVisible: false,
        currentImage: null,
        isVideo: false,
    };

    static async getInitialData(props) {
        // const token = props.initialToken ? props.initialToken : (props.token ? props.token.token : "")
        let { lang, slug } = props.match.params;

        return new Promise((resolve, reject) => {
            return Blueberry.salesrep({ slug, lang })
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    resolve({
                        title: "Salesreps - Noritex",
                        content: "",
                        protected: true,
                        status: "Salesreps",
                        seo: { title: "", description: "" },
                    });
                });
        });
    }

    gotoThankYouPage = () => {
        const { lang } = this.props.match.params;

        switch (lang) {
            case "es":
                this.props.history.push(`/es/gracias-por-registrarse-full/`);
                break;
            case "en":
                this.props.history.push(`/en/gracias-por-registrarse-full/`);
                break;
            case "pt":
                this.props.history.push(`/pt/gracias-por-registrarse-full/`);
                break;
        }
    };

    setCurrentImage = (img) => {
        this.setState({ currentImage: img });
    };

    removeHtml(html) {
        return html ? html.replace(/<\/?[^>]+(>|$)/g, "").trim() : "";
    }

    toggleModal = () => {
        this.setState((prev) => ({ isModalVisible: !prev.isModalVisible }));
    };

    openModal = (url, isVideo) => {
        this.setState((prev) => ({
            isModalVisible: true,
            currentImage: url,
            isVideo,
        }));
    };

    renderBody() {
        const { lang, slug } = this.props.match.params;
        const { user, recomendations, childCollection } = this.props.store;
        const { menusData } = this.props;
        const { currentImage, isModalVisible, isVideo } = this.state;

        const langJson = getLang(lang);

        if (this.props.data && this.props.data.status === "not-found")
            return (
                <NotFound menusData={menusData} goToSearch={this.goToSearch} />
            );

        const data = this.props.data
            ? this.props.data
            : {
                  content: "",
                  title: "Salesrep Profile - Noritex",
                  seo: {
                      title: "Salesrep Profile - Noritex",
                      page: "Conoce a nuestros vendedores",
                  },
              };
        const { pathname } = this.props.location;
        const title =
            data.seo && data.seo.title != "" ? data.seo.title : data.name;
        const description =
            data.seo && data.seo.description != ""
                ? data.seo.description
                : this.removeHtml(data.content);
        const image =
            data.seo && data.seo.images && data.seo.images.length > 0 != ""
                ? data.seo.images[0]
                : "https://www.noritex.com/facebook-ntx.jpg";
        const structured =
            data.seo && data.seo.strcutured != "" ? data.seo.strcutured : "";

        const baseUrl = this.stripLangDir(lang, pathname);
        const enUrl = this.addLangDir("en", pathname, baseUrl);
        const ptUrl = this.addLangDir("pt", pathname, baseUrl);
        const esUrl = this.addLangDir("es", pathname, baseUrl);

        return (
            <>
                <Helmet htmlAttributes={{ lang: lang }}>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta
                        name="keywords"
                        content="noritex,wholesale,decoracion,mayorista,hogar,navidad,textil,religioso"
                    />
                    {/*Alternate*/}
                    <link rel="alternate" hreflang="es" href={`${esUrl}`} />
                    <link rel="alternate" hreflang="en" href={`${enUrl}`} />
                    <link rel="alternate" hreflang="pt" href={`${ptUrl}`} />
                    <meta http-equiv="content-language" content={lang} />
                    {/*Facebook*/}
                    <meta property="og:site_name" content="Noritex" />
                    <meta
                        property="og:url"
                        content={`https://www.noritex.com${pathname}`}
                    />
                    <meta property="og:title" content={title} />
                    <meta property="og:image" content={image} />
                    <meta property="og:type" content="website" />
                    <meta property="og:description" content={description} />
                    {/*Twitter*/}
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />

                    <script type="application/ld+json">{structured}</script>
                </Helmet>

                <TopImage>
                    <img
                        src={
                            data && data.thumbs && data.thumbs.length > 1
                                ? data.thumbs[1]
                                : "https://www.noritex.com/uplds/qDaXZudtYEYyifAvsbg-salresp-2.jpeg"
                        }
                        alt=""
                    />
                </TopImage>
                {renderIf(currentImage != null && isModalVisible)(
                    <FullScreen
                        images={
                            data && data.images
                                ? data.images.map((index, i) => ({
                                      thumb: data.thumbs[i],
                                      url: index,
                                  }))
                                : []
                        }
                        toggleModal={this.toggleModal}
                        isVideo={isVideo}
                        modal={isModalVisible}
                        currentImage={currentImage}
                        set={(img) => this.setCurrentImage(img)}
                        alt={""}
                    />
                )}
                <Wrapper>
                    <Container>
                        <Columns>
                            <Column isNarrow>
                                <div
                                    style={{ position: "sticky", top: "116px" }}
                                >
                                    <SellerCard>
                                        <span className="tag">
                                            {langJson.yoursalesrep}
                                        </span>

                                        <ProfileExtended>
                                            <figure>
                                                <img
                                                    src={
                                                        data &&
                                                        data.thumbs &&
                                                        data.thumbs.length > 0
                                                            ? data.thumbs[0]
                                                            : ""
                                                    }
                                                    alt={data ? data.name : ""}
                                                />
                                            </figure>
                                            <h3>{data ? data.name : ""}</h3>
                                            <p>{langJson.positionTitle}</p>
                                        </ProfileExtended>

                                        <SellerInfo>
                                            {/* <li>
                                                <Icons icon="user-tie"></Icons>
                                                <h4>
                                                    {getYearsSinceToday(
                                                        new Date(
                                                            data &&
                                                            data.startDate
                                                                ? data.startDate
                                                                : "2018"
                                                        )
                                                    )}{" "}
                                                    {langJson.yearsOfService}
                                                </h4>
                                            </li> */}
                                            <li>
                                                <Icons icon="globe"></Icons>
                                                <h4>{langJson.languages}</h4>
                                                <p>
                                                    {data && data.languages
                                                        ? data.languages
                                                        : "spanish"}
                                                </p>
                                            </li>
                                            <li>
                                                <Icons icon="lamp"></Icons>
                                                <h4>Showroom</h4>
                                                <p>
                                                    Costa del Este, Parque
                                                    Industrial, Calle principal,
                                                    Local 104, Ciudad de Panamá{" "}
                                                </p>
                                            </li>
                                            <li>
                                                <Icons icon="warehouse-alt"></Icons>
                                                <h4>{langJson.warehouse}</h4>
                                                <p>
                                                    Calle C y Calle 16 Zona
                                                    Libre de Colón.
                                                </p>
                                            </li>
                                            {data && data.email ? (
                                                <li>
                                                    <Icons icon="envelope"></Icons>
                                                    <h4>
                                                        {" "}
                                                        {data && data.email
                                                            ? data.email
                                                            : ""}
                                                    </h4>
                                                </li>
                                            ) : null}

                                            {data && data.email ? (
                                                <li>
                                                    <Icons icon="phone"></Icons>
                                                    <h4>
                                                        {data &&
                                                        data.contactPhoneNumber
                                                            ? data.contactPhoneNumber
                                                            : ""}
                                                    </h4>
                                                </li>
                                            ) : null}
                                        </SellerInfo>

                                        <Footer>
                                            <BrandBtn
                                                whatsapp
                                                calendly
                                                as="a"
                                                target="_blank"
                                                href={
                                                    "https://api.whatsapp.com/send?phone=" +
                                                    (data &&
                                                    data.contactPhoneNumber
                                                        ? data.contactPhoneNumber
                                                        : "")
                                                }
                                            >
                                                <Icon>
                                                    <Icons icon="whatsapp"></Icons>
                                                </Icon>
                                                {langJson.contact}
                                            </BrandBtn>
                                            {data && data.calendlyLink ? (
                                                <BrandBtn
                                                    calendly
                                                    as="a"
                                                    target="_blank"
                                                    href={
                                                        data &&
                                                        data.calendlyLink
                                                            ? data.calendlyLink
                                                            : ""
                                                    }
                                                >
                                                    <Icon>
                                                        <Icons icon="calendly"></Icons>
                                                    </Icon>

                                                    {langJson.requestAMeeting}
                                                </BrandBtn>
                                            ) : null}
                                        </Footer>
                                    </SellerCard>
                                </div>
                            </Column>
                            <Column>
                                <CustomFields
                                    customFields={data ? data.customFields : {}}
                                    _t={this._t}
                                    recomendations={recomendations}
                                    location={this.props.location}
                                    childCollection={childCollection}
                                    gotoThankYouPage={this.gotoThankYouPage}
                                    viewItem={this.viewItem}
                                    toggleModalProduct={this.toggleModalProduct}
                                />
                                <Section>
                                    <Columns>
                                        {/* <TextContainer>
                                            <div>
                                                <Heading>
                                                    <h3>{langJson.aboutMe}</h3>
                                                </Heading>
                                                <PageContent
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            data && data.content
                                                                ? data.content
                                                                : "",
                                                    }}
                                                />
                                            </div>
                                        </TextContainer> */}

                                        <Gallery isNarrow>
                                            <ul>
                                                {data && data.videosUrl
                                                    ? data.videosUrl.map(
                                                          (index, i) => (
                                                              <li
                                                                  className="full"
                                                                  key={`data-video-${i}`}
                                                                  onClick={() =>
                                                                      this.openModal(
                                                                          index,
                                                                          true
                                                                      )
                                                                  }
                                                              >
                                                                  <Icons icon="play"></Icons>
                                                                  <img
                                                                      src={
                                                                          data &&
                                                                          data.thumbs &&
                                                                          data
                                                                              .thumbs
                                                                              .length >
                                                                              0
                                                                              ? data
                                                                                    .thumbs[0]
                                                                              : ""
                                                                      }
                                                                      alt={
                                                                          data &&
                                                                          data.name
                                                                              ? data.name
                                                                              : ""
                                                                      }
                                                                  />
                                                              </li>
                                                          )
                                                      )
                                                    : null}

                                                {data && data.thumbs
                                                    ? data.thumbs.map(
                                                          (index, i) => {
                                                              if (i === 0)
                                                                  return null;
                                                              return (
                                                                  <li
                                                                      key={`data-image-profile-${i}`}
                                                                      onClick={() =>
                                                                          this.openModal(
                                                                              data
                                                                                  .images[
                                                                                  i
                                                                              ],
                                                                              false
                                                                          )
                                                                      }
                                                                  >
                                                                      <img
                                                                          src={
                                                                              index
                                                                          }
                                                                          alt={
                                                                              data &&
                                                                              data.name
                                                                                  ? data.name
                                                                                  : ""
                                                                          }
                                                                      />
                                                                  </li>
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </ul>
                                        </Gallery>
                                    </Columns>
                                </Section>
                                <Section>
                                    <Container>
                                        <Banner>
                                            <img
                                                src="../ntx-banner.jpg"
                                                alt=""
                                            />
                                        </Banner>
                                        <Section>
                                            <Heading>
                                                <h3>
                                                    {langJson.presaleSystem}
                                                </h3>
                                            </Heading>
                                            <Columns>
                                                {langJson.salesSystem.map(
                                                    (index, i) => (
                                                        <Column
                                                            key={`sales-system-${i}`}
                                                        >
                                                            <InfoIcon>
                                                                <i>
                                                                    <Icons
                                                                        icon={
                                                                            index.icon
                                                                        }
                                                                    ></Icons>
                                                                </i>
                                                                <ul>
                                                                    <li>
                                                                        <h3>
                                                                            {
                                                                                index.title
                                                                            }
                                                                        </h3>
                                                                    </li>
                                                                    {index.paragaph.map(
                                                                        (
                                                                            p,
                                                                            j
                                                                        ) => (
                                                                            <li
                                                                                key={
                                                                                    i +
                                                                                    "para" +
                                                                                    j
                                                                                }
                                                                            >
                                                                                {
                                                                                    p
                                                                                }
                                                                            </li>
                                                                        )
                                                                    )}

                                                                    <li>
                                                                        <h4>
                                                                            {
                                                                                langJson.benefits
                                                                            }
                                                                        </h4>
                                                                    </li>
                                                                    {index.benefits.map(
                                                                        (
                                                                            p,
                                                                            j
                                                                        ) => (
                                                                            <li
                                                                                key={
                                                                                    i +
                                                                                    "benefits" +
                                                                                    j
                                                                                }
                                                                            >
                                                                                {
                                                                                    p
                                                                                }
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </InfoIcon>
                                                        </Column>
                                                    )
                                                )}
                                            </Columns>
                                        </Section>
                                        {/* <Section>
                                            <Heading>
                                                <h3>
                                                    {
                                                        langJson.socialResponsibility
                                                    }
                                                </h3>
                                            </Heading>
                                            <Mosaic>
                                                {langJson.socialResponsibilities.map(
                                                    (index, i) => (
                                                        <div
                                                            key={`social-responsibility${i}`}
                                                        >
                                                            <div>
                                                                <img
                                                                    src={
                                                                        index.img
                                                                    }
                                                                    alt={
                                                                        index.alt
                                                                    }
                                                                />
                                                            </div>
                                                            <div>
                                                                <h3>
                                                                    {
                                                                        index.paragraph
                                                                    }
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </Mosaic>
                                        </Section> */}
                                        <Section>
                                            <Heading>
                                                <h3>
                                                    {langJson.moreInformation}
                                                </h3>
                                            </Heading>

                                            <PostList>
                                                {langJson.moreInformationBlocks.map(
                                                    (index, i) => (
                                                        <Post
                                                            key={`more-information-${i}`}
                                                        >
                                                            <InternalLink
                                                                to={index.link}
                                                            >
                                                                <figure>
                                                                    <img
                                                                        src={
                                                                            index.img
                                                                        }
                                                                        alt={
                                                                            index.alt
                                                                        }
                                                                    />
                                                                </figure>
                                                                <h3>
                                                                    {
                                                                        index.paragraph
                                                                    }
                                                                </h3>
                                                            </InternalLink>
                                                        </Post>
                                                    )
                                                )}
                                            </PostList>
                                        </Section>
                                    </Container>
                                </Section>
                            </Column>
                        </Columns>
                    </Container>
                </Wrapper>
                <Listener user={user} action={this.props.refetch} />
            </>
        );
    }
}

export default withTracker()(Page);

// export default withSSR(Page);
